<template>
  <div v-if="totalCount > 0" class="pagination-main">
    <div class="pagination-block">
      <div class="pagination-flex">
        <p>Showing</p>
        <dropdown-wrap-vue @onClickOutside="onClickOutside">
          <div class="pagination-filter">
            <div @click="openCountSelection()" class="style-dynamic-count">
              <p v-if="rowCount">{{ Math.min(limit, rowCount) }}</p>
              <p v-else>{{ limit }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
            </div>
            <ul v-if="open" class="pagination-filter-inside">
              <li
                @click="changeFormat(ind)"
                v-for="(ind, index) in [10, 50, 100, 200]"
                :key="index">
                {{ ind }}
              </li>
            </ul>
          </div>
        </dropdown-wrap-vue>
        out of
        <div class="total-count">{{ totalCount }}</div>
      </div>
      <b-pagination
        :total="totalCount"
        v-model="page"
        range-before="1"
        range-after="1"
        order="is-centered"
        size="is-small"
        :simple="false"
        :rounded="false"
        :per-page="limit"
        icon-prev="chevron-left"
        icon-next="chevron-right">
      </b-pagination>
    </div>
  </div>
</template>

<script>
import DropdownWrapVue from '../Common/DropdownWrap.vue'

export default {
  name: 'Pagination',
  components: {
    DropdownWrapVue
  },
  // props: ['limit', 'totalCount', 'currentPage', 'onChangePage', 'onChangeLimit', 'rowCount'],
  props: {
    /** This prop indicates how many reviews should be displayed per page */
    limit: Number,
    /** This prop indicates the total number of reviews viewable under the filters applied */
    totalCount: { type: [String, Number] },
    /** This prop indicates what page of reviews the user is currently on */
    currentPage: Number,
    /** This prop indicates what behavior must be followed when the page is changed */
    onChangePage: Function,
    /** This prop indicates what behavior must be followed when the limit is changed */
    onChangeLimit: Function,
    /** Unsure */
    rowCount: Number
  },
  data () {
    return {
      page: this.currentPage, // 1,
      open: false
    }
  },
  watch: {
    page () {
      this.$emit('onChangePage', this.page)
    },
    currentPage () {
      this.page = this.currentPage
    }
  },
  methods: {
    /** This method closes the dropdown when an event is triggered outside its boundaries
     * @public
     */
    onClickOutside (event) {
      this.open = false
    },
    /** This method sets a new value for the number of reviews to be displayed per page based on what option was selected in the dropdown
     * @public
     */
    changeFormat (count) {
      this.open = false
      this.$emit('onChangeLimit', count)
    },
    /** This method closes the dropdown once an option has been selected
     * @public
     */
    openCountSelection () {
      this.open = !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-main {
  .pagination-block {
    border: none;
    border-top: var(--c-sat-driver-table-border);
    border-top-width: 1px;
    border-top-style: solid;
    font-size: 14px;
    background: var(--primary);
    display: flex;
    justify-content: space-between;
    padding: 10px 0px 0px 0px;
    .pagination-flex {
      p {
        margin: 0px;
      }
      display: flex;
      align-items: center;
      color: var(--pagination-count-text);
      .pagination-filter {
        position: relative;
        .pagination-filter-inside {
          position: absolute;
          background: var(--local-dropdown-background);
          bottom: -40%;
          left: 0%;
          max-height: 100px;
          overflow-y: auto;
          list-style-type: none;
          color: var(--pagination-count-text);
          margin: 0;
          cursor: pointer;
          li {
            padding: 0 8px;
          }
          li:first-child {
            padding-top: 5px;
          }
          li:last-child {
            padding-bottom: 5px;
          }
          li:hover {
            background-color: var(--hover-local-dropdown-background);
          }
          border-radius: 4px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        }
        .style-dynamic-count {
          padding: 0px 5px;
          height: 28px;
          cursor: pointer;
          // background: var(--tags-background-color);
          border: var(--tertiary-border-color);
          border-radius: 5px;
          margin: 0px 5px;
          display: flex;
          align-items: center;
          svg {
            margin-left: 5px;
          }
        }
      }
      .total-count {
        padding: 5px;
        border-radius: 5px;
        margin: 0px;
      }
    }
  }
}
</style>
